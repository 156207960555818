import React, { useEffect, useState } from 'react';
import { getOrderNotes } from '../../services/auth/woocommerce';

const NotasPedido = ({ orderId }) => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const fetchedNotes = await getOrderNotes(orderId);
        setNotes(fetchedNotes);
      } catch (error) {
        console.error('Error fetching order notes:', error);
      }
    };

    fetchNotes();
  }, [orderId]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4 p-4">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Notas del Pedido
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Historial de notas y cambios del pedido.
        </p>
      </div>
      <div className="border-t border-gray-200 mt-4 max-h-64 overflow-y-auto">
        <ul className="relative list-disc pl-5 space-y-4">
          {notes.slice(0, 4).map((note, index) => (
            <li key={note.id} className="flex">
              <div className="relative flex items-center">
                <span className="absolute h-full w-px bg-indigo-200 left-2 top-0"></span>
                <span className="h-2.5 w-2.5 rounded-full bg-indigo-600 z-10"></span>
              </div>
              <div className="flex justify-between items-center w-full pl-4">
                <div>
                  <p className="text-sm font-medium text-gray-900">{note.note}</p>
                  <p className="text-sm text-gray-500">{note.customer_note ? 'Nota del Cliente' : 'Nota Privada'}</p>
                </div>
                <p className="text-sm text-gray-500">{formatDate(note.date_created)}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NotasPedido;
