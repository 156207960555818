import React from 'react';

const OrderFooter = ({ newStatus, setNewStatus, onUpdateStatus }) => {
  return (
    <div className="sticky bottom-0 bg-white p-4 border-t border-gray-200 flex justify-between" style={{ maxHeight: '72px' }}>
      <select
        value={newStatus}
        onChange={(e) => setNewStatus(e.target.value)}
        className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="">Seleccionar Estado</option>
        <option value="pending">Pago Pendiente</option>
        <option value="processing">Nueva Reserva</option>
        <option value="on-hold">En Rutero</option>
        <option value="completed">Viaje Finalizado</option>
        <option value="cancelled">Viaje Cancelado</option>
        <option value="refunded">Reembolsado</option>
        <option value="failed">Pago Fallido</option>
      </select>
      <button
        onClick={onUpdateStatus}
        className="px-4 py-2 bg-black text-white rounded-md hover:bg-indigo-700 focus:outline-none"
      >
        Actualizar
      </button>
    </div>
  );
};

export default OrderFooter;
