import React from 'react';
import { TicketIcon } from '@heroicons/react/24/outline';
import { MdOutlineSurfing } from "react-icons/md";

const RouteExtras = ({ order }) => {
  const formatTotal = (total) => {
    return `$${parseFloat(total).toFixed(2)}`;
  };

  const formatPrice = (price) => {
    return `$${parseFloat(price).toFixed(2)}`;
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Ticket | Equipaje
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Detalles de enlaces y equipaje del Pasajero.
          </p>
        </div>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 items-center">
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">Enlace</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 flex flex-col space-y-2">
                {order.line_items.map(item => (
                  <span className="flex items-center" key={item.id}>
                    {item.product_id !== 22666 ? (
                      <TicketIcon className="w-6 h-6 mr-2 text-indigo-600" />
                    ) : (
                      <MdOutlineSurfing className="w-6 h-6 mr-2 text-indigo-600" />
                    )}
                    {item.name} (Precio: {formatPrice(item.price)})
                  </span>
                ))}
              </dd>
            </div>
          </div>
        </dl>
      </div>
      <div className="border-t border-gray-200">
        <div className="flex">
          <div className="w-1/3 p-4 text-center">
            <span className="block text-sm font-medium text-gray-500">Tickets</span>
            <span className="block text-sm text-gray-900">{order.line_items.filter(item => item.product_id !== 22666).reduce((total, item) => total + item.quantity, 0)}</span>
          </div>
          <div className="w-1/3 p-4 text-center border-l border-r border-gray-200">
            <span className="block text-sm font-medium text-gray-500">Tablas</span>
            <span className="block text-sm text-gray-900">{order.line_items.filter(item => item.product_id === 22666).reduce((total, item) => total + item.quantity, 0)}</span>
          </div>
          <div className="w-1/3 p-4 text-center">
            <span className="block text-sm font-medium text-gray-500">Total</span>
            <span className="block text-sm font-bold text-indigo-600">{formatTotal(order.total)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteExtras;
