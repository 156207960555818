import React, { useState } from 'react';
import { createProduct } from '../../services/auth/woocommerce';

const AgregarRuta = () => {
  const [productName, setProductName] = useState('');
  const [productStatus, setProductStatus] = useState('publish');
  const [customData, setCustomData] = useState([{ name: '', picktime: '', price: '' }]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleCreateProduct = async () => {
    try {
      const newProductData = {
        name: productName,
        status: productStatus,
        meta_data: [
          {
            key: '_custom_product_data',
            value: customData,
          },
        ],
      };

      await createProduct(newProductData);
      setShowSuccessMessage(true); // Mostrar mensaje de éxito
      clearForm(); // Limpiar el formulario después de crear el producto
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };

  const clearForm = () => {
    setProductName('');
    setProductStatus('publish');
    setCustomData([{ name: '', picktime: '', price: '' }]);
  };

  const handleAddCustomData = () => {
    setCustomData([...customData, { name: '', picktime: '', price: '' }]);
  };

  const handleCustomDataChange = (index, field, value) => {
    const newData = [...customData];
    newData[index][field] = value;
    setCustomData(newData);
  };

  const handleDeleteCustomData = (index) => {
    const newData = customData.filter((_, i) => i !== index);
    setCustomData(newData);
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <div className="container mx-auto py-4">
      <div className="max-w-lg mx-auto">
        <h2 className="text-xl font-semibold mb-4">Crear Nuevo Producto</h2>

        <label className="block mb-2">
          Nombre del Producto
          <input
            type="text"
            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 block w-full mt-1"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
        </label>

        <label className="block mb-2 mt-4">
          Estado del Producto
          <select
            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 block w-full mt-1"
            value={productStatus}
            onChange={(e) => setProductStatus(e.target.value)}
          >
            <option value="publish">Publicado</option>
            <option value="draft">Borrador</option>
          </select>
        </label>

        {customData.map((data, index) => (
          <div key={index} className="mt-4">
            <label className="block mb-2">Horario #{index + 1}</label>
            <div className="flex items-center space-x-4">
              <input
                type="text"
                className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-1/3"
                value={data.name}
                onChange={(e) => handleCustomDataChange(index, 'name', e.target.value)}
                placeholder="Nombre"
              />
              <input
                type="text"
                className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-1/3"
                value={data.picktime}
                onChange={(e) => handleCustomDataChange(index, 'picktime', e.target.value)}
                placeholder="Hora de Recogida"
              />
              <input
                type="number"
                className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-1/3"
                value={data.price}
                onChange={(e) => handleCustomDataChange(index, 'price', e.target.value)}
                placeholder="Precio"
              />
              <button
                className="text-red-600 hover:text-red-900"
                onClick={() => handleDeleteCustomData(index)}
              >
                Eliminar
              </button>
            </div>
          </div>
        ))}

        <button
          className="bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md mt-4"
          onClick={handleAddCustomData}
        >
          Agregar Horario
        </button>

        <div className="mt-4 flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md mr-2"
            onClick={handleCreateProduct}
          >
            Crear Producto
          </button>
        </div>

        {/* Modal de notificación de éxito */}
        {showSuccessMessage && (
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
            <div className="relative bg-white p-8 rounded-md shadow-lg">
              <div className="flex items-center justify-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 text-green-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M17.707 4.293a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L8 12.586l8.293-8.293a1 1 0 0 1 1.414 0z"
                  />
                </svg>
              </div>
              <p className="text-lg font-semibold mb-2">Ruta creada con éxito</p>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md mr-2"
                onClick={handleCloseSuccessMessage}
              >
                Cerrar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgregarRuta;
