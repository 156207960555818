import React, { useState, useEffect } from 'react';
import { getCustomers } from '../../services/auth/woocommerce'; // Ajusta la ruta según tu estructura

const Clientes = () => {
  const [clientes, setClientes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientesPerPage] = useState(15);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const data = await getCustomers();
        setClientes(data);
      } catch (error) {
        console.error('Error fetching customers:', error);
        // Manejo de errores según tu necesidad
      }
    };

    fetchCustomers();
  }, []); // Ejecuta solo una vez al cargar el componente

  // Lógica para paginar
  const indexOfLastCliente = currentPage * clientesPerPage;
  const indexOfFirstCliente = indexOfLastCliente - clientesPerPage;
  const currentClientes = clientes
    .filter((cliente) => {
      // Filtrar por término de búsqueda en nombre, email u otros campos relevantes
      return (
        cliente.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cliente.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cliente.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .slice(indexOfFirstCliente, indexOfLastCliente);

  // Cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Manejar cambios en el input de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página al cambiar el término de búsqueda
  };

  return (
    <div className="container mx-auto py-4">
      <h2 className="text-xl font-semibold mb-4">Lista de Clientes</h2>

      {/* Input de búsqueda */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar cliente..."
          className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-gray-200 shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <tr>
              <th className="py-3 px-6 text-left">ID</th>
              <th className="py-3 px-6 text-left">Nombre</th>
              <th className="py-3 px-6 text-left">Apellido</th>
              <th className="py-3 px-6 text-left">Compañía</th>
              <th className="py-3 px-6 text-left">Email</th>
              <th className="py-3 px-6 text-left">Teléfono</th>
              <th className="py-3 px-6 text-left">Fecha de Creación</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {currentClientes.map((cliente) => (
              <tr key={cliente.id}>
                <td className="py-3 px-6 text-left">{cliente.id}</td>
                <td className="py-3 px-6 text-left">{cliente.first_name}</td>
                <td className="py-3 px-6 text-left">{cliente.last_name}</td>
                <td className="py-3 px-6 text-left">{cliente.company}</td>
                <td className="py-3 px-6 text-left">{cliente.email}</td>
                <td className="py-3 px-6 text-left">{cliente.phone}</td>
                <td className="py-3 px-6 text-left">{cliente.date_created}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginación */}
      <div className="mt-4">
        <nav className="block">
          <ul className="flex pl-0 rounded list-none flex-wrap">
            {Array.from({ length: Math.ceil(clientes.length / clientesPerPage) }, (_, index) => (
              <li key={index}>
                <button
                  className={`bg-gray-200 hover:bg-gray-300 py-2 px-3 mr-1 rounded-md ${
                    currentPage === index + 1 ? 'bg-gray-400 text-white' : ''
                  }`}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Clientes;
