import React from 'react';
import { getStatusBadgeColor, statusNames } from '../Orders/statusHelpers';
import { MdOutlineContactEmergency, MdOutlineContactPhone } from "react-icons/md";

const CustomerInfo = ({ order, getTravelDate }) => {
  const formatPurchaseDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
    return date.toLocaleDateString('es-ES', options);
  };

  const formatTravelDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
    return date.toLocaleDateString('es-ES', options);
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Información del Pasajero
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Detalles de contacto.
          </p>
        </div>
        <div className="flex items-center">
          <span className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${getStatusBadgeColor(order.status)}`}>
            {statusNames[order.status]}
          </span>
        </div>
      </div>
      <div className="border-t border-gray-200">
        <dl className="flex flex-wrap">
          <div className="w-full sm:w-1/2 px-4 py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Nombre</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
              <span className="font-bold text-indigo-600">{order.billing.first_name}</span>{' '}
              <span className="font-semibold text-black">{order.billing.last_name}</span>
            </dd>
            <dd className="mt-1 text-sm text-gray-500 sm:mt-0">{order.billing.email}</dd>
          </div>
          <div className="w-full sm:w-1/2 px-4 py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Información del Pasajero</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 flex flex-col">
              <span className="flex items-center mb-1">
                <MdOutlineContactPhone className="w-6 h-6 mr-2 text-indigo-600" />
                {order.billing.phone || 'N/A'}
              </span>
              <span className="flex items-center mt-1">
                <MdOutlineContactEmergency className="w-6 h-6 mr-2 text-indigo-600" />
                {order.billing.email || 'N/A'}
              </span>
            </dd>
          </div>
        </dl>
      </div>
      <div className="border-t border-gray-200">
        <div className="flex">
          <div className="w-1/3 p-4 text-center">
            <span className="block text-sm font-medium text-gray-500">Fecha de Compra</span>
            <span className="block text-sm text-gray-900">{formatPurchaseDate(order.date_created)}</span>
          </div>
          <div className="w-1/3 p-4 text-center border-l border-r border-gray-200">
            <span className="block text-sm font-medium text-gray-500">Fecha de Viaje</span>
            <span className="block text-sm text-gray-900">{formatTravelDate(getTravelDate(order.meta_data))}</span>
          </div>
          <div className="w-1/3 p-4 text-center">
            <span className="block text-sm font-medium text-gray-500">Pasajeros</span>
            <span className="block text-sm text-gray-900">{order.line_items.reduce((total, item) => total + item.quantity, 0)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
