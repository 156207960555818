// src/assets/commons/Loader.js

import React from 'react';
import loaderIcon from '../images/loader.svg'; // Ajusta la ruta según tu estructura de carpetas

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-screen max-h-[70vh]">
      <div className="flex flex-col items-center max-h-full">
        <img 
          src={loaderIcon} 
          alt="Cargando" 
          className="h-12 w-12 mb-4" 
        />
        <p className="text-center text-lg">Cargando Datos...</p>
      </div>
    </div>
  );
};

export default Loader;
