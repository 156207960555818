import React, { useEffect, useState, useCallback } from 'react';
import { getOrders, updateOrderStatus } from '../../services/auth/woocommerce'; 
import Loader from '../../assets/commons/Loader'; 
import DetallesOrder from '../../components/Orders/DetallesOrder';
import OrdersTable from '../../components/Orders/OrdersTable';
import OrdersFilters from '../../components/Orders/OrderFilters';
import StatsCard from '../../components/Dashboard/StatsCard';

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [processingOrdersCount, setProcessingOrdersCount] = useState(0);
  const [refundedOrdersCount, setRefundedOrdersCount] = useState(0);
  const [lostSalesOrdersCount, setLostSalesOrdersCount] = useState(0);
  const [failedOrdersCount, setFailedOrdersCount] = useState(0);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const ordersData = await getOrders();
      setOrders(ordersData);
      calculateOrderStats(ordersData);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const calculateOrderStats = (orders) => {
    let completedOrders = 0;
    let pendingOrders = 0;
    let processingOrders = 0;
    let refundedOrders = 0;
    let lostSalesOrders = 0;
    let failedOrders = 0;

    orders.forEach(order => {
      switch (order.status) {
        case 'completed':
          completedOrders++;
          break;
        case 'pending':
        case 'on-hold':
          pendingOrders++;
          break;
        case 'processing':
          processingOrders++;
          break;
        case 'refunded':
          refundedOrders++;
          break;
        case 'cancelled':
          lostSalesOrders++;
          break;
        case 'failed':
          failedOrders++;
          break;
        default:
          break;
      }
    });

    setCompletedOrdersCount(completedOrders);
    setPendingOrdersCount(pendingOrders);
    setProcessingOrdersCount(processingOrders);
    setRefundedOrdersCount(refundedOrders);
    setLostSalesOrdersCount(lostSalesOrders);
    setFailedOrdersCount(failedOrders);
  };

  const openDrawer = (order) => {
    setSelectedOrder(order);
  };

  const closeDrawer = () => {
    setSelectedOrder(null);
  };

  const updateOrderStatusHandler = async () => {
    if (!selectedOrder || !newStatus) return;

    try {
      const response = await updateOrderStatus(selectedOrder.id, newStatus);
      console.log('Estado del pedido actualizado:', response);

      const updatedOrders = orders.map(order => {
        if (order.id === selectedOrder.id) {
          return { ...order, status: newStatus };
        }
        return order;
      });
      setOrders(updatedOrders);
      calculateOrderStats(updatedOrders);

      closeDrawer();
    } catch (error) {
      console.error('Error actualizando el estado del pedido:', error);
    }
  };

  const filteredOrders = orders.filter(order => {
    if (filterStatus && order.status !== filterStatus) return false;
    if (filterDate && !order.meta_data.some(meta => meta.key === '_billing_fecha_viaje' && meta.value === filterDate)) return false;
    if (searchTerm && !order.number.toString().includes(searchTerm.toLowerCase())) return false;
    return true;
  });

  const paginatedOrders = filteredOrders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRefresh = () => {
    fetchOrders();
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Lista de Pedidos</h1>
      <OrdersFilters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        handleRefresh={handleRefresh}
        orders={orders}
      />
      {loading ? (
        <div className="flex items-center justify-center h-64">
          <Loader />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mb-6">
            <StatsCard title="Viajes Completados" value={completedOrdersCount} color="#10b981" ordersCount={completedOrdersCount} />
            <StatsCard title="Nuevas Reservas" value={processingOrdersCount} color="#3b82f6" ordersCount={processingOrdersCount} />
            <StatsCard title="En Rutero" value={pendingOrdersCount} color="#f59e0b" ordersCount={pendingOrdersCount} />
            <StatsCard title="Viajes Reembolsados" value={refundedOrdersCount} color="#ef4444" ordersCount={refundedOrdersCount} />
            <StatsCard title="Viajes Cancelados" value={lostSalesOrdersCount} color="#64748b" ordersCount={lostSalesOrdersCount} />
            <StatsCard title="Viajes Fallidos" value={failedOrdersCount} color="#ff5722" ordersCount={failedOrdersCount} />
          </div>
          <OrdersTable orders={paginatedOrders} openDrawer={openDrawer} />
          <div className="flex justify-center mt-4">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`px-4 py-2 mx-1 border rounded-md ${currentPage === index + 1 ? 'bg-indigo-600 text-white' : 'bg-white text-indigo-600 hover:bg-indigo-700 hover:text-white'}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}
      {selectedOrder && (
        <DetallesOrder
          order={selectedOrder}
          onClose={closeDrawer}
          onUpdateStatus={updateOrderStatusHandler}
          newStatus={newStatus}
          setNewStatus={setNewStatus}
        />
      )}
    </div>
  );
};

export default OrdersList;
