import React from 'react';

const OrderHeader = ({ order, onClose }) => (
  <div className="sticky top-0 bg-[#111827] text-white py-2 px-4 flex items-center justify-between w-full" style={{ maxHeight: '70px' }}>
    <h2 className="text-lg font-semibold">Editar Pedido {order.number}</h2>
    <button className="p-2 text-white hover:text-gray-400" onClick={onClose}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
);

export default OrderHeader;
