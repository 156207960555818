// src/components/FacturaCliente.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOrder } from '../../services/auth/woocommerce';
import AlertsOrders from '../DetallesOrders/AlertsOrders';
import CustomerInfo from '../DetallesOrders/CustomerInfo';
import RouteExtras from '../DetallesOrders/RouteExtras';
import TravelDetails from '../DetallesOrders/TravelDetails';
import PaymentAlert from '../DetallesOrders/PaymentAlert';
import OrderInfoHeader from '../DetallesOrders/OrderInfoHeader';

const FacturaCliente = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderData = await getOrder(orderId);
        setOrder(orderData);
      } catch (error) {
        console.error('Error fetching order:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  const getTravelDate = (metaData) => {
    const travelDateMeta = metaData.find(meta => meta.key === '_billing_fecha_viaje');
    return travelDateMeta ? travelDateMeta.value : '';
  };

  const getPickupDetails = (metaData) => {
    const pickupMeta = metaData.find(meta => meta.key === '_billing_pickup');
    return pickupMeta ? pickupMeta.value : 'N/A';
  };

  const getDropoffDetails = (metaData) => {
    const dropoffMeta = metaData.find(meta => meta.key === '_billing_dropoff');
    return dropoffMeta ? dropoffMeta.value : 'N/A';
  };

  const getPaymentMethod = () => {
    return order.payment_method_title || 'Tarjeta de Crédito / Débito';
  };

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Cargando...</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div id={`facturaCliente-${order.id}`} className="bg-white p-6 w-full max-w-4xl shadow-lg">
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">Su Factura</h1>
            <p className="mt-1 text-lg text-gray-600">Gracias por reservar con Transporzuma. Buen Viaje!</p>
          </div>
        </header>
        <OrderInfoHeader order={order} />
        <div className="space-y-4">
          <AlertsOrders order={order} />
          <CustomerInfo order={order} getTravelDate={getTravelDate} />
          <RouteExtras order={order} />
          <TravelDetails order={order} getPickupDetails={getPickupDetails} getDropoffDetails={getDropoffDetails} />
          <PaymentAlert order={order} getPaymentMethod={getPaymentMethod} formatDate={formatDate} />
        </div>
      </div>
    </div>
  );
};

export default FacturaCliente;
