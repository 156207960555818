import React from 'react';

const OrdersFilters = ({ searchTerm, setSearchTerm, filterStatus, setFilterStatus, filterDate, setFilterDate, itemsPerPage, setItemsPerPage, handleRefresh, orders }) => {
  return (
    <div className="flex mb-4">
      <input
        type="text"
        placeholder="Buscar por número de pedido"
        className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mr-2"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <select
        className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        value={filterStatus}
        onChange={(e) => setFilterStatus(e.target.value)}
      >
        <option value="">Filtrar por Estado</option>
        <option value="pending">Pago Pendiente</option>
        <option value="processing">Nueva Reserva</option>
        <option value="on-hold">En Rutero</option>
        <option value="completed">Viaje Finalizado</option>
        <option value="cancelled">Viaje Cancelado</option>
        <option value="refunded">Reembolsado</option>
        <option value="failed">Pago Fallido</option>
      </select>
      <input
        type="date"
        className="ml-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        value={filterDate}
        onChange={(e) => setFilterDate(e.target.value)}
      />
      <select
        className="ml-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        value={itemsPerPage}
        onChange={(e) => setItemsPerPage(Number(e.target.value))}
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
        {orders && <option value={orders.length}>Mostrar Todos</option>}
      </select>
      <button
        className="ml-2 px-4 py-2 bg-black text-white rounded-md hover:bg-indigo-700 focus:outline-none"
        onClick={handleRefresh}
      >
        Refrescar
      </button>
    </div>
  );
};

export default OrdersFilters;
