// src/hooks/useIdleTimer.js
import { useEffect, useRef } from 'react';

const useIdleTimer = (onIdle, timeout = 300000) => {
  const timerRef = useRef(null);

  useEffect(() => {
    const handleActivity = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(onIdle, timeout);
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Inicializar el timer
    handleActivity();

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [onIdle, timeout]);

  return null;
};

export default useIdleTimer;
