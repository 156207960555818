import axios from 'axios';

const WooCommerce = axios.create({
  baseURL: 'https://transporzuma.com/wp-json/wc/v3/',
  auth: {
    username: 'ck_36a9844e94d1d75f44b4eef77153ecd1567bc5d1',
    password: 'cs_c6084655267ef99380e748ac83a1a79fbf27fe8d',
  },
});

// Función para obtener todos los productos con paginación manual
export const getProducts = async () => {
  try {
    let page = 1;
    let allProducts = [];
    let totalPages = 1;

    // Loop hasta que se obtengan todos los productos
    while (page <= totalPages) {
      const response = await WooCommerce.get('products', {
        params: {
          per_page: 100, // Ajusta esto según cuántos productos quieres obtener por página
          page: page,
        },
      });

      allProducts = [...allProducts, ...response.data];
      totalPages = response.headers['x-wp-totalpages'];
      page++;
    }

    return allProducts;
  } catch (error) {
    throw new Error(`Error fetching products: ${error.message}`);
  }
};

// Función para obtener todos los pedidos con paginación manual
export const getOrders = async () => {
  try {
    let page = 1;
    let allOrders = [];
    let totalPages = 1;

    // Loop hasta que se obtengan todos los pedidos
    while (page <= totalPages) {
      const response = await WooCommerce.get('orders', {
        params: {
          per_page: 100, // Ajusta esto según cuántos pedidos quieres obtener por página
          page: page,
        },
      });

      allOrders = [...allOrders, ...response.data];
      totalPages = response.headers['x-wp-totalpages'];
      page++;
    }

    return allOrders;
  } catch (error) {
    throw new Error(`Error fetching orders: ${error.message}`);
  }
};

// Función para actualizar el estado de un pedido
export const updateOrderStatus = async (orderId, status) => {
  try {
    const response = await WooCommerce.put(`orders/${orderId}`, { status });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating order status: ${error.message}`);
  }
};

// Función para agregar una nota a un pedido
export const addOrderNote = async (orderId, note) => {
  try {
    const response = await WooCommerce.post(`orders/${orderId}/notes`, { note });
    return response.data;
  } catch (error) {
    throw new Error(`Error adding order note: ${error.message}`);
  }
};

// Función para actualizar un producto
export const updateProduct = async (productId, updatedData) => {
  try {
    const response = await WooCommerce.put(`products/${productId}`, updatedData);
    return response.data;
  } catch (error) {
    throw new Error(`Error updating product: ${error.message}`);
  }
};

// Función para obtener las variaciones de un producto
export const getProductVariations = async (productId) => {
  try {
    const response = await WooCommerce.get(`products/${productId}/variations`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching product variations: ${error.message}`);
  }
};

// Función para eliminar un producto
export const deleteProduct = async (productId) => {
  try {
    const response = await WooCommerce.delete(`products/${productId}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error deleting product: ${error.message}`);
  }
};

// Función para crear un producto
export const createProduct = async (newProductData) => {
  try {
    const response = await WooCommerce.post('products', newProductData);
    return response.data;
  } catch (error) {
    throw new Error(`Error creating product: ${error.message}`);
  }
};

// Función para crear un pedido
export const createOrder = async (newOrderData) => {
  try {
    const response = await WooCommerce.post('orders', newOrderData);
    return response.data;
  } catch (error) {
    throw new Error(`Error creating order: ${error.message}`);
  }
};

// Función para obtener todos los clientes
export const getCustomers = async () => {
  try {
    const response = await WooCommerce.get('customers', {
      params: {
        per_page: 100, // Ajusta según cuántos clientes quieres obtener por página
      },
    });

    const customers = response.data.map((customer) => ({
      id: customer.id,
      date_created: customer.date_created,
      first_name: customer.first_name,
      last_name: customer.last_name,
      company: customer.company,
      email: customer.email,
      phone: customer.phone,
    }));

    return customers;
  } catch (error) {
    throw new Error(`Error fetching customers: ${error.message}`);
  }
};

// Función para obtener las notas de un pedido
export const getOrderNotes = async (orderId) => {
  try {
    const response = await WooCommerce.get(`orders/${orderId}/notes`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching order notes: ${error.message}`);
  }
};

// Función para obtener un pedido específico por ID
export const getOrder = async (orderId) => {
  try {
    const response = await WooCommerce.get(`orders/${orderId}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching order: ${error.message}`);
  }
};


export default WooCommerce;
