import React from 'react';
import { getStatusBadgeColor, statusNames } from '../Orders/statusHelpers';

const OrderRow = ({ order, openDrawer }) => {
  const getTravelDate = (metaData) => {
    const travelDateMeta = metaData.find(meta => meta.key === '_billing_fecha_viaje');
    return travelDateMeta ? travelDateMeta.value : 'N/A';
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const formatTravelDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60);

    const formattedDate = date.toLocaleDateString('es-ES', options);
    const [day, month, year] = formattedDate.split(' ');

    return (
      <>
        <span>{day}</span>{' '}
        <span className="text-indigo-600">{month.toUpperCase()}</span>{' '}
        <span>{year}</span>
      </>
    );
  };

  return (
    <tr>
      <td className="border px-4 py-2">{order.number}</td>
      <td className="border px-4 py-2 text-left">
        <span className="text-indigo-600">{capitalize(order.billing.first_name)}</span>{' '}
        <span className="text-gray-600">{capitalize(order.billing.last_name)}</span>
      </td>
      <td className="border px-4 py-2 text-left">
        <ul>
          {order.line_items.filter(item => item.product_id !== 22666).map(item => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
      </td>
      <td className="border px-4 py-2">{order.line_items.reduce((total, item) => total + item.quantity, 0)}</td>
      <td className="border px-4 py-2">
        {order.line_items.find(item => item.product_id === 22666) ? 
          order.line_items.find(item => item.product_id === 22666).quantity : 0
        }
      </td>
      <td className="border px-4 py-2">
        {formatTravelDate(getTravelDate(order.meta_data))}
      </td>
      <td className="border px-4 py-2">${order.total}</td>
      <td className="border px-4 py-2">
        <span className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${getStatusBadgeColor(order.status)}`}>
          {statusNames[order.status]}
        </span>
      </td>
      <td className="border px-4 py-2">
        <button onClick={() => openDrawer(order)} className="text-blue-500 hover:text-blue-700">Editar</button>
      </td>
    </tr>
  );
};

export default OrderRow;
