import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import AlertsOrders from '../DetallesOrders/AlertsOrders';
import OrderHeader from '../DetallesOrders/OrderHeader';
import OrderFooter from '../DetallesOrders/OrderFooter';
import CustomerInfo from '../DetallesOrders/CustomerInfo';
import RouteExtras from '../DetallesOrders/RouteExtras';
import TravelDetails from '../DetallesOrders/TravelDetails';
import PaymentAlert from '../DetallesOrders/PaymentAlert';
import UpdateOrderModal from '../DetallesOrders/UpdateOrderModal';
import OrderUpdatedModal from '../DetallesOrders/OrderUpdatedModal';
import OrderNotes from '../DetallesOrders/OrderNotes';
import NotasPedido from '../DetallesOrders/NotasPedido'; // Importa el componente
import OrderInfoHeader from '../DetallesOrders/OrderInfoHeader';
import { addOrderNote, updateOrderStatus } from '../../services/auth/woocommerce';

const DetallesOrden = ({ order, onClose, onUpdateStatus, newStatus, setNewStatus }) => {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isUpdatedModalOpen, setUpdatedModalOpen] = useState(false);
  const [isOrderNotesModalOpen, setOrderNotesModalOpen] = useState(false);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('es-ES', options);
  };

  const getTravelDate = (metaData) => {
    const travelDateMeta = metaData.find(meta => meta.key === '_billing_fecha_viaje');
    return travelDateMeta ? travelDateMeta.value : '';
  };

  const getPickupDetails = (metaData) => {
    const pickupMeta = metaData.find(meta => meta.key === '_billing_pickup');
    return pickupMeta ? pickupMeta.value : 'N/A';
  };

  const getDropoffDetails = (metaData) => {
    const dropoffMeta = metaData.find(meta => meta.key === '_billing_dropoff');
    return dropoffMeta ? dropoffMeta.value : 'N/A';
  };

  const getPaymentMethod = () => {
    return order.payment_method_title || 'Tarjeta de Crédito / Débito';
  };

  const handleUpdateStatus = async () => {
    if (newStatus === 'cancelled') {
      setUpdateModalOpen(false);
      setOrderNotesModalOpen(true);
    } else {
      try {
        await updateOrderStatus(order.id, newStatus);
        onUpdateStatus();
        setUpdateModalOpen(false);
        setUpdatedModalOpen(true);
      } catch (error) {
        console.error('Error updating order status:', error);
      }
    }
  };

  const handleOrderNotesSubmit = async (note) => {
    try {
      await addOrderNote(order.id, note);
      await updateOrderStatus(order.id, newStatus);
      onUpdateStatus();
      setOrderNotesModalOpen(false);
      setUpdatedModalOpen(true);
    } catch (error) {
      console.error('Error adding order note:', error);
    }
  };

  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={300}
      classNames="drawer"
      unmountOnExit
    >
      <div className="fixed inset-0 z-50 flex justify-end" onClick={(e) => { if (e.target === e.currentTarget) onClose(); }}>
        <div id={`detalleCliente-${order.id}`} className="bg-white p-6 max-w-[732px] w-full h-full max-h-screen overflow-auto shadow-lg flex flex-col justify-between">
          <OrderHeader order={order} onClose={onClose} />
          <div className="p-6 flex-grow space-y-4">
            <OrderInfoHeader order={order} />
            <AlertsOrders order={order} />
            <CustomerInfo order={order} getTravelDate={getTravelDate} />
            <RouteExtras order={order} />
            <TravelDetails order={order} getPickupDetails={getPickupDetails} getDropoffDetails={getDropoffDetails} />
            <PaymentAlert order={order} getPaymentMethod={getPaymentMethod} formatDate={formatDate} />
            <NotasPedido orderId={order.id} /> {/* Añade el componente aquí */}
          </div>
          <OrderFooter
            newStatus={newStatus}
            setNewStatus={setNewStatus}
            onUpdateStatus={() => setUpdateModalOpen(true)}
          />
        </div>
        <UpdateOrderModal
          isOpen={isUpdateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
          onConfirm={handleUpdateStatus}
        />
        <OrderNotes
          isOpen={isOrderNotesModalOpen}
          onClose={() => setOrderNotesModalOpen(false)}
          onSubmit={handleOrderNotesSubmit}
          orderStatus={newStatus}
        />
        <OrderUpdatedModal
          isOpen={isUpdatedModalOpen}
          onClose={() => setUpdatedModalOpen(false)}
        />
      </div>
    </CSSTransition>
  );
};

export default DetallesOrden;
