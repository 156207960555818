// src/components/OrderInfoHeader.js
import React from 'react';
import Logo from '../../assets/images/logotrans.svg';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const OrderInfoHeader = ({ order }) => {

  const handleShare = () => {
    const shareUrl = `${window.location.origin}/factura-cliente/${order.id}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('Enlace copiado al portapapeles');
    });
  };

  const handleDownloadPDF = async () => {
    // Buscar el contenedor adecuado en función de la presencia de 'facturaCliente'
    const input = document.getElementById(`facturaCliente-${order.id}`) || document.getElementById(`detalleCliente-${order.id}`);
    if (!input) {
      console.error('Elemento con ID facturaCliente o detalleCliente no encontrado');
      return;
    }

    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(`Factura_${order.number}.pdf`);
  };

  return (
    <div className="mb-6 border-b border-gray-300 pb-4">
      <div className="flex justify-between items-center">
        <img src={Logo} alt="Logo" className="w-[25%] h-auto" />
        <span className="text-lg font-semibold">Factura #{order.number}</span>
        <div className="flex space-x-2">
          <button
            onClick={handleShare}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-indigo-600 hover:text-white focus:outline-none"
          >
            Compartir
          </button>
          <button
            onClick={handleDownloadPDF}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-indigo-600 hover:text-white focus:outline-none"
          >
            Descargar
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderInfoHeader;
