// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Quitamos Navigate
import { auth } from './firebase';
import Navbar from './components/Navbar';
import MisPedidos from './pages/MisPedidos/MisPedidos';
import Dashboard from './pages/Dashboard/Dashboard';
import Footer from './components/Footer';
import LoginUser from './components/auth/LoginUser';
import Rutas from './pages/MisRutas/MisRutas';
import NuevaRuta from './pages/MisRutas/AddRuta';
import Clientes from './pages/MisClientes/Clientes';
import FacturaCliente from './components/DetallesOrders/FacturaCliente';
import useIdleTimer from './hooks/useIdleTimer';
import NotFoundPage from './components/NotFoundPage'; // Importar el componente NotFoundPage

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState('dashboard');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setIsLoggedIn(false);
    });
  };

  useIdleTimer(() => {
    if (isLoggedIn) {
      handleLogout();
    }
  }, 300000); // 5 minutos de inactividad

  const renderPage = () => {
    switch (currentPage) {
      case 'dashboard':
        return <Dashboard />;
      case 'orders':
        return <MisPedidos />;
      case 'rutas':
        return <Rutas />;
      case 'nueva-ruta':
        return <NuevaRuta />;
      case 'clientes':
        return <Clientes />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        {isLoggedIn && <Navbar setCurrentPage={setCurrentPage} onLogout={handleLogout} />}
        <main className="flex-1">
          <Routes>
            <Route path="/" element={isLoggedIn ? renderPage() : <LoginUser onLogin={handleLogin} />} />
            <Route path="/factura-cliente/:orderId" element={<FacturaCliente />} />
            <Route path="*" element={<NotFoundPage />} /> {/* Ruta para la página 404 */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
