import React from 'react';

const StatsCard = ({ title, value, color, description, ordersCount }) => (
  <div className="p-4 bg-white border border-gray-200 rounded-lg flex-1">
    <h3 className="text-sm font-medium text-gray-500">{title}</h3>
    <p className="text-2xl font-bold" style={{ color }}>{value}</p>
    <span className={`text-sm ${color.includes('green') ? 'text-green-500' : color.includes('red') ? 'text-red-500' : 'text-gray-500'}`}>
      {ordersCount} Facturas
    </span>
  </div>
);

export default StatsCard;
