import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { getProducts, updateProduct, deleteProduct } from '../../services/auth/woocommerce'; // Adjust the path if necessary
import Loader from '../../assets/commons/Loader'; // Adjust the path if necessary

const Rutas = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);
  const [productStatus, setProductStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const productsPerPage = 10;

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const products = await getProducts();
      setProducts(products);
      setFilteredProducts(products); // Initially show all products
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filtered = products.filter(product => {
      const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = statusFilter === '' || product.status === statusFilter;
      return matchesSearch && matchesStatus;
    });

    setFilteredProducts(filtered);
  }, [products, searchTerm, statusFilter]);

  const openModal = (product) => {
    setEditedProduct(product);
    setProductStatus(product.status);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditedProduct(null);
    setProductStatus('');
  };

  const handleSaveChanges = async () => {
    try {
      const updatedProduct = {
        ...editedProduct,
        status: productStatus,
        meta_data: [
          {
            key: '_custom_product_data',
            value: editedProduct.meta_data.find(meta => meta.key === '_custom_product_data')?.value,
          },
        ],
      };
      await updateProduct(editedProduct.id, updatedProduct);
      fetchProducts();
      closeModal();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleProductNameChange = (e) => {
    setEditedProduct({
      ...editedProduct,
      name: e.target.value,
    });
  };

  const handleCustomDataChange = (index, field, value) => {
    const newCustomData = [...editedProduct.meta_data.find(meta => meta.key === '_custom_product_data').value];
    newCustomData[index][field] = value;
    setEditedProduct({
      ...editedProduct,
      meta_data: editedProduct.meta_data.map(meta => 
        meta.key === '_custom_product_data' ? { ...meta, value: newCustomData } : meta
      ),
    });
  };

  const addNewCustomData = () => {
    const newCustomData = [
      ...editedProduct.meta_data.find(meta => meta.key === '_custom_product_data')?.value,
      { name: '', picktime: '', price: '' }
    ];
    setEditedProduct({
      ...editedProduct,
      meta_data: editedProduct.meta_data.map(meta => 
        meta.key === '_custom_product_data' ? { ...meta, value: newCustomData } : meta
      ),
    });
  };

  const deleteCustomData = (index) => {
    const newCustomData = editedProduct.meta_data.find(meta => meta.key === '_custom_product_data')?.value.filter((_, i) => i !== index);
    setEditedProduct({
      ...editedProduct,
      meta_data: editedProduct.meta_data.map(meta => 
        meta.key === '_custom_product_data' ? { ...meta, value: newCustomData } : meta
      ),
    });
  };

  const handleDeleteProduct = async () => {
    try {
      await deleteProduct(editedProduct.id);
      fetchProducts();
      closeModal();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  // Pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / productsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="container mx-auto py-4">
      {loading && <Loader />} {/* Show loader while loading */}
      {!loading && (
        <>
          <div className="flex flex-col">
            <div className="flex justify-between mb-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Buscar productos por nombre"
                  className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="relative">
                <select
                  className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="">Todos los estados</option>
                  <option value="publish">Activo</option>
                  <option value="draft">Inactivo</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto">
              <div className="min-w-full py-2 align-middle inline-block">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre del Producto</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {currentProducts.map((product) => (
                        <tr key={product.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{product.name}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${product.status === 'publish' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                              {product.status === 'publish' ? 'Publicado' : 'Borrador'}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <button
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => openModal(product)}
                            >
                              Editar
                            </button>
                            <button
                              className="text-red-600 hover:text-red-900 ml-4"
                              onClick={() => handleDeleteProduct(product.id)}
                            >
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <nav className="mt-4">
              <ul className="flex justify-center">
                {pageNumbers.map(number => (
                  <li key={number} className="mx-1">
                    <button
                      className={`px-4 py-2 border border-gray-300 rounded-md ${currentPage === number ? 'bg-indigo-500 text-white' : 'bg-white text-gray-700'}`}
                      onClick={() => paginate(number)}
                    >
                      {number}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            <Transition show={isModalOpen} as={Fragment}>
              <Dialog as="div" open={isModalOpen} onClose={closeModal}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                  <Dialog.Panel className="bg-white rounded-lg max-w-4xl mx-auto p-6">
                    <Dialog.Title className="text-lg font-semibold">Editar Ruta</Dialog.Title>
                    <div className="mt-4">
                      <label htmlFor="productName" className="block text-sm font-medium text-gray-700">Nombre de la ruta</label>
                      <input
                        id="productName"
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={editedProduct?.name || ''}
                        onChange={handleProductNameChange}
                      />
                    </div>
                    <div className="mt-4">
                      <label htmlFor="productStatus" className="block text-sm font-medium text-gray-700">Estado</label>
                      <select
                        id="productStatus"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={productStatus}
                        onChange={(e) => setProductStatus(e.target.value)}
                      >
                        <option value="publish">Activo</option>
                        <option value="draft">Inactivo</option>
                      </select>
                    </div>
                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700">Enlaces Disponibles</label>
                      {editedProduct?.meta_data.find(meta => meta.key === '_custom_product_data')?.value.map((data, index) => (
                        <div key={index} className="flex items-center mt-2">
                          <input
                            type="text"
                            placeholder="Nombre"
                            className="border border-gray-300 rounded-md py-2 px-3 mr-2 flex-1"
                            value={data.name}
                            onChange={(e) => handleCustomDataChange(index, 'name', e.target.value)}
                          />
                          <input
                            type="text"
                            placeholder="Hora de Pick Up"
                            className="border border-gray-300 rounded-md py-2 px-3 mr-2 flex-1"
                            value={data.picktime}
                            onChange={(e) => handleCustomDataChange(index, 'picktime', e.target.value)}
                          />
                          <input
                            type="text"
                            placeholder="Precio"
                            className="border border-gray-300 rounded-md py-2 px-3 mr-2 flex-1"
                            value={data.price}
                            onChange={(e) => handleCustomDataChange(index, 'price', e.target.value)}
                          />
                          <button
                            type="button"
                            className="text-red-600 hover:text-red-900"
                            onClick={() => deleteCustomData(index)}
                          >
                            Eliminar
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="mt-2 text-indigo-600 hover:text-indigo-900"
                        onClick={addNewCustomData}
                      >
                        + Nuevo Enlace
                      </button>
                    </div>
                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                        onClick={closeModal}
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        className="bg-indigo-500 text-white px-4 py-2 rounded-md"
                        onClick={handleSaveChanges}
                      >
                        Guardar Cambios
                      </button>
                    </div>
                  </Dialog.Panel>
                </div>
              </Dialog>
            </Transition>
          </div>
        </>
      )}
    </div>
  );
};

export default Rutas;
