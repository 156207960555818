import React from 'react';
import OrderRow from './OrderRow';

const OrdersTable = ({ orders, openDrawer }) => {
  return (
    <div className="overflow-x-auto">
      <table className="table-auto min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-200 text-gray-700 text-center">
          <tr>
            <th className="px-4 py-2">Pedido</th>
            <th className="px-4 py-2">Cliente</th>
            <th className="px-4 py-2">Ruta</th>
            <th className="px-4 py-2">Pasajeros</th>
            <th className="px-4 py-2">Tablas</th>
            <th className="px-4 py-2">Fecha de Viaje</th>
            <th className="px-4 py-2">Total</th>
            <th className="px-4 py-2">Estado</th>
            <th className="px-4 py-2">Ver</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-center">
          {orders.map(order => (
            <OrderRow key={order.id} order={order} openDrawer={openDrawer} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersTable;