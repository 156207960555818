import React from 'react';
import KuvLogoBlanco from '../assets/images/kuv-digital-logo.svg';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4">
      <div className="container mx-auto text-center">
        <img
          className="h-8 w-auto mx-auto"
          src={KuvLogoBlanco}
          alt="KUV Digital"
        />
        <p className="mt-4">&copy; 2024 KUV Digital, Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;

