import React from 'react';

const TravelDetails = ({ order, getPickupDetails, getDropoffDetails }) => (
  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Detalles del Viaje
      </h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Detalles de Salida y Llegada del Pasajero.
          </p>
    </div>
    <div className="border-t border-gray-200">
      <dl>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-indigo-600">PICKUP</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {getPickupDetails(order.meta_data)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-indigo-600">DROPOFF</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {getDropoffDetails(order.meta_data)}
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
);

export default TravelDetails;
