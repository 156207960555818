// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBccS95NgGqeBb6RLI-FV1vYNHZDAt6xN8",
    authDomain: "crm-transporzuma.firebaseapp.com",
    projectId: "crm-transporzuma",
    storageBucket: "crm-transporzuma.appspot.com",
    messagingSenderId: "1066214023208",
    appId: "1:1066214023208:web:c95fa663a95bedb88f151d"
};

// Inicializar Firebase
const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
