import React, { useEffect, useState } from 'react';
import { getOrders } from '../../services/auth/woocommerce';
import Loader from '../../assets/commons/Loader'; // Asegúrate de que la ruta sea correcta
import StatsCard from '../../components/Dashboard/StatsCard';
import RecentOrders from '../../components/Dashboard/RecentOrders';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [pendingRevenue, setPendingRevenue] = useState(0);
  const [refunded, setRefunded] = useState(0);
  const [lostSales, setLostSales] = useState(0);
  const [recentOrders, setRecentOrders] = useState([]);
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [refundedOrdersCount, setRefundedOrdersCount] = useState(0);
  const [lostSalesOrdersCount, setLostSalesOrdersCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersData = await getOrders();
        
        setOrders(ordersData);
        calculateRevenueAndExpenses(ordersData);
        setRecentOrders(ordersData.slice(0, 5)); // Mostrar los 5 pedidos más recientes

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateRevenueAndExpenses = (orders) => {
    let totalRevenue = 0;
    let totalPendingRevenue = 0;
    let totalRefunded = 0;
    let totalLostSales = 0;
    let completedOrders = 0;
    let pendingOrders = 0;
    let refundedOrders = 0;
    let lostSalesOrders = 0;

    orders.forEach(order => {
      const orderTotal = parseFloat(order.total);
      switch (order.status) {
        case 'completed':
          totalRevenue += orderTotal;
          completedOrders++;
          break;
        case 'pending':
        case 'on-hold':
          totalPendingRevenue += orderTotal;
          pendingOrders++;
          break;
        case 'refunded':
          totalRefunded += orderTotal;
          refundedOrders++;
          break;
        case 'cancelled':
        case 'failed':
          totalLostSales += orderTotal;
          lostSalesOrders++;
          break;
        default:
          break;
      }
    });

    setRevenue(totalRevenue);
    setPendingRevenue(totalPendingRevenue);
    setRefunded(totalRefunded);
    setLostSales(totalLostSales);
    setCompletedOrdersCount(completedOrders);
    setPendingOrdersCount(pendingOrders);
    setRefundedOrdersCount(refundedOrders);
    setLostSalesOrdersCount(lostSalesOrders);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <h2 className="text-lg font-semibold mb-4">Últimos 30 días</h2>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
          <StatsCard title="Total de Pedidos" value={orders.length} color="#4338ca" description={`7 Facturas`} ordersCount={orders.length} />
          <StatsCard title="Pagos Completados" value={`$${revenue.toFixed(2)}`} color="#10b981" description={`7 Facturas`} ordersCount={completedOrdersCount} />
          <StatsCard title="Pagos Pendientes" value={`$${pendingRevenue.toFixed(2)}`} color="#f59e0b" description={`7 Facturas`} ordersCount={pendingOrdersCount} />
          <StatsCard title="Pagos Reembolsados" value={`$${refunded.toFixed(2)}`} color="#ef4444" description={`7 Facturas`} ordersCount={refundedOrdersCount} />
          <StatsCard title="Viajes Cancelados" value={`$${lostSales.toFixed(2)}`} color="#64748b" description={`7 Facturas`} ordersCount={lostSalesOrdersCount} />
        </div>
      </div>

      <RecentOrders recentOrders={recentOrders} />
    </div>
  );
};

export default Dashboard;
